@import '../css/shared';

.account-home-page {

  .PageWithSubpageHeader {

    .title {
      font-size: $h1-font-size;
      font-weight: 700;
    }
    
  }

  .box-home-account {
    h6 {
      text-transform: uppercase;
    }
  }

  .box-home-account, .help {
    border: 1px solid $gray-300;
    h6, svg {
      color: $primary !important;
    }
  }  
  .box-home-account-orders {
    > div {
      border: 1px solid $gray-300;
    }
  }
  
}