@import '../css/shared';

.article-page {
  h1 {
    line-height: 1.11111;
  }

  .categories {
    overflow-x: auto;
    a {
      white-space: nowrap;
    }
  }
  .summary {
    font-size: 1.125rem;
    line-height: 1.875rem;
  }
  @include media-breakpoint-up(md) {
    .summary {
      font-size: 1.375rem;
      line-height: 2.5rem;
    }
  }
}
