@import '../shared';

.cart-campaign-single-offer-buyxgety-flattened-skus {
    padding: spacer(3) !important;
 //   border: 1px solid $gray-300;
    @extend .bg-gold-light;
    .name {
        @extend .fw-bold, .text-decoration-underline;
    }
    .edit {
        @extend .extra-small, .text-decoration-underline;
    }
    .cart-campaign-single-offer-buyxgety-flattened-sku {
        @extend .px-2
    }
}

.cart-campaign-single-offer-buyxgety-flattened-skus-picker {
  .title {
    @extend .h3, .text-uppercase,;
  }
  .subtitle {
    @extend .small;
  }
  .skus {
    .sku {
      .name {
        @extend .fw-bold;
      }
      .select {
        @extend .btn-outline-primary;
      }
      &.selected {
        .select {
          @extend .btn-primary;
        }
      }
    }
  }
}
