@import '../css/shared';

.form-group {
  .form-label.required:after {
    content: '*';
    margin-left: spacer(1);
    color: $primary;
  }
}

.pagination {
  .page-item {
    position: relative;
    //padding: 0 map-get($spacers, 2);

    &.active {
      .page-link::after {
        content: '';
        height: 4px;
        background-color: $purina-red;
        display: block;
        position: absolute;
        width: 22px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.account-loyalty-page {
  $circle-dimension-sm: 130px;
  $circle-dimension-md: 190px;

  .box-points {
    color: $white;
    width: $circle-dimension-sm;
    height: $circle-dimension-sm;
    border-radius: 50%;
    background-color: $yellow;

    .points {
      font-size: 1.875rem;
      line-height: 1;
    }

    @include media-breakpoint-up(md) {
      width: $circle-dimension-md;
      height: $circle-dimension-md;

      .points {
        font-size: 2.9375rem;
      }
    }
  }

  @include media-breakpoint-down(md) {
    .border-sm {
      border: 1px solid $gray-300;
    }
  }
}

// Salesforce Chat
// .b2x-env-production {
.embeddedServiceHelpButton .helpButton .uiButton {
  background-color: #005290;
  font-family: 'Arial', sans-serif;
}
.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #005290;
}
// }

// Salesforce chat test
// .b2x-env-test {
//   .embeddedServiceHelpButton .helpButton .uiButton {
//     background-color: #E11616;
//     font-family: "Salesforce Sans", sans-serif;
//   }
//   .embeddedServiceHelpButton .helpButton .uiButton:focus {
//     outline: 1px solid #E11616;
//   }
// }

.Breadcrumb {
  .breadcrumb-item {
    font-size: $font-size-base * 0.75;

    a {
      color: $black;
      text-decoration: none;
    }

    & + .breadcrumb-item:before {
      content: '';
      float: none;
      padding: 0;
      display: inline-block;
      width: 6px;
      height: 6px;
      top: -1px;
      position: relative;
      margin-right: 11px !important;
      border-right: 1px solid $gray-400;
      border-bottom: 1px solid $gray-400;
      transform: rotate(-45deg);
    }

    &.active {
      color: $gray-400;
    }
  }
}

.top-suggestions {
  .title {
    font-weight: 700;
  }
  .product {
    .name {
      font-size: $small-font-size;
      font-weight: 700;
    }
    .price {
      font-weight: 700;
    }
    .crossed-out-price {
      font-weight: 700;
    }
  }
}
