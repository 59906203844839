@import 'functions';
@import 'bootstrap';
@import 'mixins';
@import 'fonts';
@import 'b2x-components';
@import 'b2x-theme/b2x-theme';

// Decommentare questo stile per debuggare i CSS.
// * {
//   background-color: rgba(green, 0.05);
// }

body {
  overflow-y: scroll;
}

::-webkit-input-placeholder {
  font-size: $small-font-size !important;
}

:-moz-placeholder {
  font-size: $small-font-size !important;
}

::-moz-placeholder {
  font-size: $small-font-size !important;
}

.ot-pc-refuse-all-handler,
.save-preference-btn-handler {
  background-color: $white !important;
  color: $promo !important;
  border: 0 !important;
  padding: 0 10px !important;
  text-decoration: underline !important;

  &:focus {
    outline: 0 !important;
  }
}

#onetrust-pc-btn-handler,
#onetrust-reject-all-handler {
  background-color: $white !important;
  color: $promo !important;
  border: 0 !important;
  text-decoration: underline !important;

  &:focus {
    outline: 0 !important;
  }
}

@include media-breakpoint-down(sm) {
  .wishlist-modal {
    .modal-footer {
      display: grid;
      justify-content: initial;
    }
  }
}

#ot-sdk-btn-floating.ot-floating-button {
  z-index: 900 !important;
}


.referral-friend-box {
  .swiper {
    padding-bottom: 10px;

    .swiper-pagination-bullets {
      bottom: 0px;

      .swiper-pagination-bullet {
        background: $gray-300;

        &.swiper-pagination-bullet-active {
          background: $primary;
        }
      }
    }
  }
}