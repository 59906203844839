@import '../shared';

.circle-progress-bar {
  .background {
    stroke: $gray-200;
  }
  .bar {
    stroke: $primary;
  }
  .current-value {
    @extend .fw-bold;
    font-size: 40px;
  }
}
