@import 'css/shared';

.mobile-navigation-offcanvas {
  .logo {
    min-height: 42px;
    max-width: 150px;

    img {
      width: 100%;
    }
  }

  .simple-search-form {
    .form-group {
      margin-bottom: map-get($spacers, 2);
      background-color: $gray-100;

      input {
        background-color: $gray-100;
        border: 0 !important;
      }
    }
  }

  .Drawer.active {
    z-index: 10;
  }

  .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    min-width: 42px;
  }
}
