@import '../css/shared';

.product-slider {

  @include media-breakpoint-up(xl) {

    padding: 0 12px;

    .swiper-button-prev.swiper-button-large {left: -12px;}
    .swiper-button-next.swiper-button-large {right: -12px;}

  }

  @include media-breakpoint-down(xl) {

    .slider-navigation {display: none;}

  }


}