@import '../css/shared';

.account-page {

  .PageWithSubpageHeader {

    .title {
      font-size: $h1-font-size;
      font-weight: 700;
    }
    
  }

  .box-content {
    box-shadow: none !important;
    border: 1px solid $gray-300;
  }
  
  .inner-box {
    border-radius: 0;
    box-shadow: none !important;
    border: 1px solid $gray-300;

    .cta-title {
      font-weight: 400;
      color: $red;
    }

  }

  .inner-address-box {
    .btn {font-weight: 400;color: $red;}
  }

  .subpage-title-container {
    h6 {
      text-transform: uppercase;
      font-size: $h4-font-size;
    }
  }

  .menu-account {

    .btn {font-weight: normal;}

    .list-group {

      .list-group-item {
        border-radius: 0;
        margin-bottom: spacer(1) !important;
        border: 1px solid $gray-300 !important;

        &.active {
            background-color: $primary !important;
            color: $white !important;

            .Icon {
              color: $white;
            }
        }

        .Icon {
          font-size: 26px !important;
          color: $primary;
        }

      }

    }

  }

}