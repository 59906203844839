@import '~bootstrap/scss/functions';
@import 'variables';

// RIDEFINISCO ALCUNE COSE SENZA MODIFICARLE, GIUSTO PER AVERLE DIPONIBILI >>>>>

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
) !default;

// BOOTSTRAP OVERRIDES BEFORE IMPORT >>>>>

$enable-negative-margins: true;

$custom-container-max-widths: (
  xxl: 1434px,
);

$font-family-sans-serif: 'Antenna', sans-serif;
$font-size-base: 1rem; // 16px`

$headings-font-weight: 400;

$line-height-base: 1.5;

$h1-font-size: $font-size-base * 1.875;
$h2-font-size: $font-size-base * 1.625;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.25;
$h5-font-size: $font-size-base * 1.125;

$small-font-size: 0.875rem;
$extra-small-font-size: $font-size-base * 0.75;

$display-font-sizes: (
  1: 4.5rem,
  2: 4rem,
  3: 3.5rem,
  4: 3rem,
  5: 2.5rem,
  6: 2rem,
);

$border-widths: (
  5: 1.8125rem,
);

$black: #252930;
$white: #ffffff;
$red: #e91c24;
$purina-red: #ed1c24;
$promo: #e91c24;
$yellow: #ffbf05;
$green: #84aa4d;
$gold: #B89055;
$gold-light: #f3eadc;
$gray-100: #f5f5f5;
$gray-200: #e9e9e9;
$gray-300: #c7c7c7;
$gray-400: #7d7d7d;

$custom-colors: (
  'black': $black,
  'white': $white,
  'red': $red,
  'purina-red': $purina-red,
  'promo': $promo,
  'yellow': $yellow,
  'gold': $gold,
  'gold-light': $gold-light,
  'gray-100': $gray-100,
  'gray-200': $gray-200,
  'gray-300': $gray-300,
  'gray-400': $gray-400,
  'green': $green,
);

$body-bg: $white;
$body-color: $black;

$primary: $red;
$secondary: $black;
$border-color: $gray-200;

$accordion-button-focus-box-shadow: none;
$accordion-button-active-bg: $body-bg;
$accordion-button-active-color: $body-color;

$offcanvas-horizontal-width: 500px;
$offcanvas-padding-x: 3rem;
$offcanvas-border-width: 0;

$modal-header-border-width: 0;
$modal-footer-border-width: 0;

$input-btn-line-height: 1.5;
$btn-font-size: 1rem;
$btn-font-weight: bold;
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 20px;
$btn-padding-x: 1.5rem;
$btn-padding-y: 0.5rem;
$input-padding-y: 0.5rem;

$input-border-color: $gray-300;

$input-placeholder-color: $gray-300;
$form-check-input-disabled-opacity: 0.3;

$input-focus-border-color: $black;
$input-btn-focus-box-shadow: 0 0 0 0.25rem rgba(0, 0, 0, 0.3);

$form-select-focus-box-shadow: $input-btn-focus-box-shadow;

$form-check-input-checked-bg-color: $black;

$form-label-margin-bottom: 0.25rem;
$form-label-font-size: $small-font-size;

$input-border-radius: 0;
$form-check-input-border-radius: $input-border-radius;
$form-select-border-radius: $input-border-radius;

$breadcrumb-divider: quote('/');

$badge-font-size: 0.875em;

$pagination-border-width: 0;
$pagination-color: $black;
$pagination-bg: transparent;
$pagination-border-radius: 0;
$pagination-active-color: $black;
$pagination-active-bg: transparent;
$pagination-hover-color: $gray-400;
$pagination-hover-bg: transparent;
$pagination-focus-color: $gray-400;
$pagination-focus-bg: transparent;
$pagination-focus-box-shadow: none;

$dropdown-padding-x: 1rem;
$dropdown-padding-y: 1rem;

// BOOTSTRAP OVERRIDES BEFORE IMPORT <<<<<

@import '../../node_modules/bootstrap/scss/variables';
//@import '/node_modules/bootstrap/scss/utilities';

$container-max-widths: map-merge($container-max-widths, $custom-container-max-widths);
$theme-colors: map-merge($theme-colors, $custom-colors);
$theme-colors-rgb: map-loop($theme-colors, to-rgb, '$value');
$utilities-text: map-merge($utilities-text, $custom-colors);
$utilities-text-colors: map-loop($utilities-text, rgba-css-var, '$key', 'text');
$utilities-bg: map-merge($utilities-bg, $custom-colors);
$utilities-bg-colors: map-loop($utilities-bg, rgba-css-var, '$key', 'bg');
