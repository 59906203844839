@import 'css/shared';

.cart-offcanvas {

  .footer-message {
    padding: map-get($spacers, 3) 0 0;
    text-align: center;
  }

  .offcanvas-header {

    .offcanvas-title {
      text-transform: none !important;
      font-weight: 700;

      span {
        color: $black !important;
        margin-left: map-get($spacers, 1) !important;
      }

    }

    svg {
      font-size: 20px;
    }

  }

  .cart-packs {
    padding: 0 !important;
    margin: 0 !important;

    .cart-pack {
      @include media-breakpoint-up(lg) {
        .cart-sku-action {
          padding-top: map-get($spacers, 3) !important;
        }
      }
    }

    .cart-sku {

      &:first-child>.row {
        padding-top: 0 !important;
      }

      &>.row {
        .product-name {
          max-width: 230px;
        }
        .cart-sku-action {

          .cart-sku-price {
            font-weight: 300 !important;

            .pe-3 {
              padding-right: map-get($spacers, 2) !important;
            }

            .text-offer {
              color: $promo;
            }

          }

          .btn {
            font-weight: 400 !important;
          }

        }

      }

    }

  }

  .cart-offcanvas-total {
    padding: map-get($spacers, 4) 0 !important;

    &+div {
      padding-top: 0 !important;
    }

  }

  .cart-offcanvas-to-free-shipping {
    padding: 0 !important;
  }

  @include media-breakpoint-down(md) {
    .CartSkuQuantityForm {
      select {
        width: 90px;
      }
    }
  }

}