@import 'css/shared';

.footer {
  position: relative;

  .pre-footer-row {
    background-repeat: no-repeat;
  }

  .footer-newsletter {
    input[type='email'] {
      border-width: 0;
      border-radius: 0;
    }
  }

  .accordion-sublink:hover {
    opacity: 0.7;
  }

  @include media-breakpoint-down(lg) {
    /*.footer-social {
      border-top: 1px solid $black;
    }*/

    .accordion {
      .accordion-item {
        border-style: solid;
        border-width: 1px 0 0 0;
        border-color: $gray-300;
        border-radius: 0;
      }

      .accordion-header button {
        box-shadow: none;
      }
    }

    .accordion .row > [class^='col-']:last-child .accordion-item {
      border-color: $gray-300;
    }

    #second-footer-accordion {
      .col-lg:first-child {
        .accordion-item {
          border-top-color: transparent;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .accordion .row > [class^='col-'] .accordion-item {
      border-bottom: 0 !important;
    }
  }
}
