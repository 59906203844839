@import '../css/shared';

.search-filter-slider {
  overflow: hidden;

  .slider-navigation {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 96px;
  }

  .swiper-button-prev {left: 0 !important;}
  .swiper-button-next {right: 0 !important;}

  .filter {
  
    .filter-image-container {
      border-color: transparent !important;
      border-width: 3px !important;
      transition: all 0.3s;
    }

    &:hover .filter-image-container,
    &.active .filter-image-container {
      border-color: $primary !important;
    }

  }
    

  @include media-breakpoint-up(lg) {
    .filter-image-container {
      max-width: 96px;
    }
  }

  @include media-breakpoint-down(lg) {
    .filter-image-container {
      max-width: 80px;
    }
  }

  @include media-breakpoint-down(sm) {

    .slider-navigation {
      display: none;
    }

    .swiper {
      overflow: visible;
    }

  }

}