@import 'css/shared';

.assembled-product-tile {

  .price-block {

      @include media-breakpoint-up(sm) {
        font-size: $h5-font-size;
      }
      @include media-breakpoint-down(sm) {
        font-size: $h6-font-size;
      }

    .crossed-out-price,
    .discount-percentage {
      margin-right: map-get($spacers, 2 );
    }

  }

  @include media-breakpoint-up(md) {

    .tile-product-info {
      border: 1px solid $border-color;
      padding: map-get( $spacers, 3 );
    }

  }

  @include media-breakpoint-down(md) {
    
    .tile-product-info {
      padding: map-get( $spacers, 3 ) 0;
    }

  }

}