@import '../css/shared';

.search-page {
  .order-dropdown {
    button:after {
      display: none;
    }
  }

  .search-filters {
    // .accordion {

    //   & > .accordion-item > .accordion-header {
    //     & > div > div:nth-child(2) {
    //       padding: 0 calc(#{map-get($spacers, 1)} + 3px) 0 0 !important;
    //     }
    //   }

    //   .accordion {

    //     .accordion-header {
    //       & > div > div:nth-child(2) {
    //         padding: 0 calc(#{map-get($spacers, 2)} + 1px) 0 0 !important;

    //         .btn {padding: 0 !important;}
    //         svg {font-size: 14px !important;}
    //       }
    //     }

    //   }

    // }

    @include media-breakpoint-up(lg) {

      .search-result {
        display: none;
      }

    }

    .main-filter {
      .btn,
      .form-check-label {
        text-transform: uppercase;
        color: $black !important;
        font-weight: 700;
      }
    }
  }

  @include media-breakpoint-up(lg) {
  }
  @include media-breakpoint-down(lg) {
  }
}
