@import '../css/shared';

.vertical-slider {
  width: 100%;
  
  .swiper {
    height: 30px;
   
    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-pagination {
      position: absolute;
      left: 0;
      top: auto;
      right: 0;
      bottom: 3px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: none;

      .swiper-pagination-bullet {
        display: block;
        margin: 0 3px;
        width: 4px;
        height: 4px;
      }
    }

}
  
}