@import 'css/shared';

$fade-duraion: 200ms;

.mobile-header {
  background-color: $white;
  transition: background-color $fade-duraion;
  .logo {
    max-width: 150px;
    min-height: 42px;
    display: flex;
    align-items: center;

    img {
      width: 100%;
    }
  }

  .logo-mobile {
    max-width: 150px;
  }

  .toggle-item,
  .menu-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    min-width: 42px;
  }
}

.MobileHeader {
  svg.icon-wishlist {
    stroke: $black;
  }
}

.MobileMenu {
  max-width: calc(100% - 50px);

  .Mmenu {
    .slide {
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      transform: translateX(100%);
      transition: transform 200ms;

      &.active {
        display: block;
        transform: translateX(0%);
      }

      &.child-active {
        display: block;
        transform: translateX(-10%);
      }
    }
  }
}
