@import '../css/shared';

.service-page {

  .PageWithSubpageHeader {

    .title {
      font-size: $h1-font-size;
      font-weight: 700;
    }

    .subpage-title-container {
      .subpage-title {
        text-transform: uppercase;
        font-size: $h4-font-size;
      }
    }
    
  }

  .box-content {
    box-shadow: none !important;
    border: 1px solid $gray-300;
  }

  .aside-menu-title {
    text-transform: uppercase;
    font-weight: 700;
    background-color: $gray-100 !important;
    color: $primary;
    margin-bottom: 0 !important;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  .aside-menu-item {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
  .faq-title {
    text-transform: uppercase;
    font-weight: 700;
  }

}