@import 'css/shared';

.reviews {

  select {min-width: 250px;}

  .title {
    font-weight: bold;
  }

  .summary {

    @include media-breakpoint-up(md) {
      max-width: 300px;
    }

    .rating-distribution {


      td {
        padding: 3px 0 !important;

        &.rating-title {
          padding: 3px 10px 3px 5px !important;
        }

      }

      .progress {
        border-radius: 0;
        background: rgba(255, 255, 255, 0.4);
    
        .progress-bar {
          background: $white;
        }
    
      }

    }

    .summary-info {
      background: $secondary;
      color: $white;
    }

    .summary-action {
      border: 2px solid $secondary;

      h5 {font-weight: bold;}


      .btn {
        display: block;
        width: 100%;
      }

    }

    .average {
      font-size: map-get($display-font-sizes, 2);
      font-weight: bold;
      
      @include media-breakpoint-up(md) {
        font-size: map-get($display-font-sizes, 1);
      }

    }

    .star-empty svg path {
      opacity: 0.4;
      stroke: $white;
      fill: $white;
    }
    

    .star svg path {
      stroke: $white;
      fill: $white;
    }

  }

  .review-item-list {

    hr {
      margin: map-get($spacers, 4) 0;
    }

    .review {
      display: flex;
      flex-direction: column;

      .name         {order: 2;}
      .rating       {order: 1;}
      .review-info  {order: 3;}
      .message      {order: 4;}
      
      .name,
      .review-info {margin-bottom: map-get($spacers, 3);}

      .name {
        font-size: $h5-font-size;
        font-weight: 700;
      }

      .review-info {
        font-size: $font-size-base * 0.75;
      }

      .rating-stars {

        .stars-empty > div:not(:first-child),
        .stars > div:not(:first-child) {
          margin-left: 10px !important;
        }

        .star-empty svg path {
          opacity: 0.2;
          stroke: $secondary;
          fill: $secondary;
        }
    
        .star svg path {
          stroke: $secondary;
          fill: $secondary;
        }
    

      }      
    
    }

    & + .btn {
      padding-left: 0;
      padding-right: 0;
      color: $black;
      text-decoration: underline;
      background: transparent !important;
      outline: none !important;
      border: 0 !important;
      box-shadow: none !important;
    }

  }

  @include media-breakpoint-up(lg) {}
  @include media-breakpoint-down(lg) {}
  
}

.review-form-modal {

  .modal-title {
    visibility: hidden;  
  }

  .modal-body {
    padding: 0 1.5rem 1.5rem;
  }

  .review-modal-image {
    height: 100%;
    object-fit: cover;
  }
  
  h3 {
    font-size: $h2-font-size;
    font-weight: 600;
    margin-bottom: 2rem;
  }

  .radio-rating-star {
    margin-right: 1rem !important;
    svg {
      cursor: pointer;
    }
  }
  
  .rating-form-group {
    span.form-label {
      margin-bottom: 1rem;
    }
  }

}