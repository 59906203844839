@import '../css/shared';

.landing-template-b-page {

  section.products {

    @include media-breakpoint-down(xl) {

      overflow: hidden;

      .swiper {
        overflow: visible;
      }

      .slider-navigation {
        display: none;
      }

    }

  }

}