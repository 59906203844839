@font-face {
  font-family: 'PurinaScript';
  font-style: normal;
  font-weight: normal;
  src: local('Purina-Script'), url('../fonts/Purina-Script.woff') format('woff');
}

@font-face {
  font-family: 'Antenna';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/Antenna-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Antenna';
  font-style: normal;
  font-weight: 400;
  src:  url('../fonts/Antenna-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Antenna';
  font-style: normal;
  font-weight: 700;
  src:  url('../fonts/Antenna-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Salesforce Sans';
  src: url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.woff') format('woff'),
  url('https://c1.sfdcstatic.com/etc/clientlibs/sfdc-aem-master/clientlibs_base/fonts/SalesforceSans-Regular.ttf') format('truetype');
}

.ff-purina {
  font-family: 'PurinaScript';
}

.fw-light {
  font-weight: 200 !important;
}

.extra-small {
  font-size: $extra-small-font-size;
}
  