@import '../css/shared';

.landing-template-a-page {
  .table-container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &>div {
      display: flex;
      flex-direction: row;
      border-bottom: .5px solid $gray-400;

      &:last-child {
        border: 0px;
      }

      &>div {
        width: 100%;
        border-right: .5px solid $gray-400;

        &:last-child {
          border: 0px;
        }
      }
    }

    @include media-breakpoint-down(md) {
      flex-direction: row;

      &>div {
        flex-direction: column;
        border: 0px;
        border-right: .5px solid $gray-400;

        &>div {
          border: 0px;
          border-bottom: .5px solid $gray-400;
        }
      }
    }
  }
}