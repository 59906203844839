@import 'css/shared';

.product-page {

  .price-block {

    .crossed-out-price,
    .price {
      font-size: $h4-font-size;
    }

    .crossed-out-price,
    .discount-percentage {
      margin-right: map-get($spacers, 3 );
    }

    .discount-percentage {
      padding: 0 map-get($spacers, 2 );
      background-color: $promo;
    }

  }
 
}

.product-sticky-footer {

  .price-block {

    .crossed-out-price,
    .discount-percentage {
      margin-right: map-get($spacers, 2 );
    }

    .discount-percentage {
      padding: 0 map-get($spacers, 1 );
    }

  }
 
}

.product-tile {

  .price-block {

    .crossed-out-price,
    .discount-percentage {
      margin-right: map-get($spacers, 2 );
    }

    @include media-breakpoint-up(sm) {
      font-size: $h5-font-size;
    }
    @include media-breakpoint-down(sm) {
      font-size: $h6-font-size;
    }

  }
  
}
