@import '../css/shared';

.popup-newsletter-modal {

  .cta-container {
    
    .btn {
      display: block;
      width: 100%;
    }

  }

}