@import '../css/shared';

.blog-page {
  .first-level-category {
    overflow-x: auto;
    a {
      white-space: nowrap;
      padding: 0.75rem 0.5rem;
      color: $gray-300;
      text-decoration: none;
      margin-right: 2rem;
      &:focus {
        box-shadow: none;
      }
      &:hover,
      &.active {
        color: $black;
      }
      &.active {
        border-bottom: 2px solid $black;
        font-weight: bold;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    span {
      white-space: nowrap;
    }
  }
}
.content-wrapper {
  .content-bottom-wrapper {
    overflow-x: auto;
    a {
      white-space: nowrap;
    }
    .tags {
      a {
        text-decoration: none;
      }
    }
  }
}
