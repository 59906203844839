@import '../css/shared';

.cart-page {

  .cart-gift-card {
    .btn {
      color: $red;
    }
  }
  .cart-loyalty-points {
    padding: map-get($spacers, 2);
    font-size: $small-font-size;
    line-height: $line-height-sm;
    background: $gray-200;

    .Icon {
      font-size: 18px;
      color: $primary;
    }

  }

  .cart-packs {
    border: 1px solid $gray-300;

    .cart-pack {

      &>.cart-sku:first-child>div {
        padding-top: 0 !important;
      }

      &>.cart-sku:last-child>div {
        padding-bottom: 0 !important;
      }

      .cart-sku {

        .SeparatedList {
          .btn {
            font-weight: 400;
            color: $black;
          }
        }

        .text-offer {
          color: $promo;
        }

        .cart-sku-info ul li {
          font-size: $small-font-size;
          font-weight: 300;
        }

        @include media-breakpoint-up(lg) {
          .cart-sku-action {
            padding: 0 !important;

            .cart-sku-price {
              font-size: $h6-font-size;
            }

            &>div:first-child {

              &>.row>.col {
/*                 width: 100%;
                display: flex;
                justify-content: flex-end;

                &:first-child {
                  margin-bottom: 10px;
                } */

              }

            }

          }

        }

      }

    }

    img.img-fluid {
      @include media-breakpoint-up(lg) {
        max-width: 105px !important;
      }

      max-width: 105px !important;
    }

  }

  .cart-summary {

    h6 {
      font-weight: 700;
    }

    .cart-to-free-shipping {
      font-weight: 400;
    }

    &>div:last-child {
      h5 {
        font-size: $h3-font-size;
        font-weight: 700;
      }

      border: 1px solid $gray-300;
    }

  }

  .cart-footer {
    border: 1px solid $gray-300;

    h6 {
      font-weight: 700;
    }

    @include media-breakpoint-up(lg) {
      padding: map-get($spacers, 4) map-get($spacers, 3) !important;

      h6 {
        margin-bottom: map-get($spacers, 4);
      }
    }

  }

  .Accordion {
    .row>[class^='col-'] {
      .accordion-item {
        border-bottom: 0;
        border-color: $gray-200;
      }

      &:last-child {
        .accordion-item {
          border-bottom: 1px solid $gray-200;
        }
      }
    }

    .accordion-item {
      border-left: 0;
      border-right: 0;
    }

    .accordion-button:not(.collapsed) {
      box-shadow: none !important;
    }

    .accordion-body {
      button[type="submit"] {
        color: $secondary;
        border-color: $gray-300 !important;
      }
    }

    @include media-breakpoint-down(lg) {}

    @include media-breakpoint-up(lg) {}

  }

  .cart-to-free-shipping {
    border: 1px solid $gray-300;

    .progress-bar {
      background: $green;
    }
  }

  .generic-coupon-form {

    input {
      border-color: $gray-300;

      &:focus {
        border-color: $primary;
      }

      &.is-invalid {
        border-color: $red;
      }
    }

    input+.btn {
      border-left: 1px solid $gray-300 !important;
    }

    input:focus+.btn {
      border-color: $primary !important;
      border-left: 1px solid $primary !important;
    }

    input.is-invalid+.btn {
      border-color: $red !important;
      border-left: 1px solid $red !important;
    }
  }

  #form-cartGenericCoupon {
    input {
      border-color: var(--bs-dark) !important;
    }

    button {
      z-index: 100;
      opacity: 1 !important;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 1px;
        height: calc(100% - 14px);
        background: var(--bs-dark);
      }
    }
  }

  .assembled-component-product-list {

    li {
      font-size: $extra-small-font-size !important;
      margin-bottom: map-get( $spacers, 1 );
    }

  }

}