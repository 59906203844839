@import '../css/shared';

.product-page {

  .product-accordion {

    .accordion-header .accordion-button,
    .accordion-body {
      font-size: $small-font-size;
    }
  }

  .btn-check {

    &+label {
      cursor: pointer;
    }

    &:checked+label {
      background: $secondary !important;
      color: $white !important;
    }

  }

  .gallery-sticky-container {
    position: relative;
  }

  // .gallery-sticky-container {
  //   position: sticky;
  //   @include media-breakpoint-up(lg) {
  //     top: 100px;
  //   }
  // }

  .product-slide-image-container {
    // border-radius: 30px 30px 0 0;
    overflow: hidden;

    img {
      mix-blend-mode: multiply;
    }
  }

  .product-image-gallery {

    .swiper-pagination {
      display: none;
      padding: 10px 0;
      justify-content: center;
      background: $white;
      position: relative;
      bottom: 0;

      .swiper-pagination-bullet {
        display: block;
        border: 1px solid transparent;
        padding: 3px;
        width: 15px;
        height: 15px;
        margin: 0 3px;
        overflow: hidden;

        &.swiper-pagination-bullet-active {
          border-color: $gray-400;
        }

        &:before {
          content: '';
          background: $gray-400;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

    }



    @include media-breakpoint-down(md) {

      .thumbnail-container {
        display: none;
      }

      .swiper-pagination {
        display: flex;
      }

      .gallery-zoom-text {
        bottom: 35px !important;
      }

    }

    .gallery-container {

      img {
        //max-width: 500px;
        width: 100%;
      }

    }

    .thumbnail-container {

      img {
        transition: all 0.3s;
        mix-blend-mode: multiply;
      }

      .swiper-slide {
        cursor: pointer;
        transition: all 0.3s;

        &>div {
          padding: 10px;

          @include media-breakpoint-up(md) {
            width: 120px;
            height: 120px;
          }

          @include media-breakpoint-down(md) {
            width: 80px;
            height: 80px;
          }

        }

        &:hover img {
          opacity: 0.8;
        }

      }

      .swiper-slide-thumb-active {
        border-color: $gray-300 !important;

        img {
          opacity: 1 !important;
        }

      }

    }

  }

  @include media-breakpoint-down(lg) {

    .product-main {
      overflow: hidden;
    }

  }

  .related-products {

    @include media-breakpoint-down(xl) {

      overflow: hidden;

      .swiper {
        overflow: visible;
      }

    }

  }

  .reviews-summary-preview {

    .btn-review-first {
      background-color: $white;
      border-color: $primary;
      color: $primary;
      line-height: 1;
      font-weight: 400;
      font-size: $small-font-size;
      padding: map-get($spacers, 1) map-get($spacers, 2);
    }

    .reviews-count {
      font-weight: 400;
      font-size: $font-size-base * 0.75;
      ;
    }

    .star-empty svg path {
      opacity: 0.2;
      stroke: $secondary;
      fill: $secondary;
    }

    .star svg path {
      stroke: $secondary;
      fill: $secondary;
    }

  }
}

.product-sticky-footer {

  .btn {
    font-size: $small-font-size;
  }

  @include media-breakpoint-down(lg) {
    .btn {
      padding: 0.5rem 1rem !important;
    }
  }

}