@import '../css/shared';

.newsletter-subscribe-page {

  .newsletter-subscribe-page-subtitle mark {
    background: none;
    padding: 0;
    color: $promo;
  }

  .NewsletterForm {

    .cta-container {
      text-align: center !important;

      >button {
        width: 100%;
      }
    }

  }

  @include media-breakpoint-up(lg) {

    .page-content {
      padding: 50px 0 300px;
    }

  }

  @include media-breakpoint-down(lg) {}

}