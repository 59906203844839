@import 'css/shared';

.product-tile {

  border-radius: $btn-border-radius-lg $btn-border-radius-lg 0 0;
  overflow: hidden;

  .product-tile-media-container {

    img {
      mix-blend-mode: multiply;
    }
    
  }

  .product-tile-basic-info {
    .best-price {
      .Icon {
        font-size: 14px !important;
        position: relative;
      }
    }
  }

  .btn-check {

    &+label {
      cursor: pointer;
    }

    &:checked+label {
      background: $secondary !important;
      color: $white !important;
    }

  }

  .discount-percentage {
    text-align: center;
    position: absolute;
    width: 200px;
    left: -100px;
    top: 0;
    z-index: 10;
    transform: rotate(-45deg);
    transform-origin: top center;
    padding: 25px 50px 5px;

    @include media-breakpoint-up(sm) {
      padding: 30px 50px 5px;
    }

  }

  @include media-breakpoint-down(xxl) {

    .availability-email-notification-button {
      padding: 0.5rem !important;
    }
    
  }

  .reviews-summary-preview {

    .reviews-count {
      display: flex;
      align-items: center;
    }

    svg {
      width: 12px;
      height: 12px;
    }

    .star-empty svg path {
      opacity: 0.2;
      stroke: $secondary;
      fill: $secondary;
    }

    .star svg path {
      stroke: $secondary;
      fill: $secondary;
    }

  }

  @include media-breakpoint-up(md) {}

  @include media-breakpoint-down(md) {

    border: 0;

  }

}