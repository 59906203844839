@import 'css/shared';

.top-bar {
  background: $primary;
  color: $white;
  padding: 1px 0;
}

.desktop-header {
  .header-first-row {
    z-index: $zindex-sticky + 1;
    position: relative;
    padding-top: 22px;

    svg.icon-wishlist {
      stroke: $black;
    }
  }

  .logo {
    height: 38px;
    margin-top: 3px;
  }

  .toggles {
    .toggle-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 44px;
      min-width: 44px;
    }
  }

  .sub-menu-dropdown {
    z-index: 3;
    opacity: 0;
    transform: translateY(-15px);
    transition: opacity 0.8s ease, transform 1s ease;

    &.mounted {
      opacity: 1;
      transform: translateY(0px);
    }

    .double-column {
      flex: 2 0;

      ul {
        column-count: 2;
      }
    }
  }

  .submenu-link {
    display: inline-block;
    position: relative;

    &:hover {
      opacity: 0.7;
    }
  }

  .menu {
    & > .menu-item {
      padding: 0 0 0;
      transition: all 0.3s;

      a,
      button {
        padding: 16px 0 28px;

        &:before {
          content: '';
          transition: all 0.3s;
          width: 0;
          height: 0;
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 0;
          background: $primary;
        }

        &:hover:before,
        &.active:before {
          width: calc(100% - calc(#{map-get($spacers, 3)} * 2));
          height: 5px;
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .toggles {
      .toggle-account {
        position: relative;

        &:before,
        &:after {
          content: '';
          width: 1px;
          height: 30px;
          position: absolute;
          background: $secondary;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:after {
          right: 0;
        }

        &:before {
          left: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
  }
}

.header-checkout {

  .logo {
    max-width: 280px;

    @include media-breakpoint-down(lg) {
      max-width: 150px;
    }

  }

  .free-shipping-from {
    font-size: 0.75rem;
  }

  .checkout-back-link {
    text-transform: uppercase;
    font-size: $small-font-size;
    text-decoration: none;

    @include media-breakpoint-down(lg) {
      font-size: 0.75rem;
    }

    & > div > div:last-child {
      padding-top: 1px;
    }

  }

}