@import '../css/shared';

.checkout-page {

  .text-info {
    color: $primary !important;
  }

  a {
    color: $red !important;
  }
  .checkout-back-link {
    color: $black;
    text-transform: uppercase;
    text-decoration: none;
    font-size: $small-font-size;


    & div:last-child {
      padding-top: 1px;
    }

    @include media-breakpoint-down(lg) {
      font-size: $font-size-base * 0.75;
    }

  }

  .card-header {
    .step-number {
      background: $gray-100;
    }

    .step-title {
      font-size: $h4-font-size;
      font-weight: 700;
    }

    .btn {
      font-weight: 400;
      color: $red !important;
    }

  }
  .card-body {
        .btn {
      font-weight: 400;
    }

  }

  .checkout-step {

    .card {
      border-radius: 0;
      border-color: $gray-200 !important;

      .card-body.show {

        &.bg-white {
          border: 1px solid $gray-300;
          overflow: hidden;
        }

      }

    }

  }

  .CartEmailForm {
    
    h5 {
      font-weight: 700;
    }
    
  }

  .CustomerAddressForm {

    .actions-container {
      justify-content: flex-start !important;
    }

  }
  
  .checkout-summary {
    border: 1px solid $gray-300;
    overflow: hidden;

    h5 {
      padding: 0 0 map-get($spacers, 3) 0;
      margin: 0;
      font-size: $h3-font-size;
      font-weight: 700;
    }

    .cart-packs {
      padding: 0 !important;
      margin: 0 !important;

      .cart-sku:last-child {
        & > div {
          padding-bottom: 0 !important;
        }
      }

      .cart-sku-info {

        & > .row > .col:last-child > div {
            padding: 0 !important;
            & > div {
              margin: 0 !important;
            }
        }

        .product-name {
          font-size: $small-font-size;
          margin-bottom: map-get($spacers, 1) !important;
        }

        .product-sku-name {
          margin: 0 !important;
        }

        .text-offer {
          color: $promo;
        }

        img.img-fluid {
          @include media-breakpoint-up(lg) {
            max-width: 90px !important;
          }
    
          max-width: 90px !important;
        }
    
        .cart-sku-price {
          font-size: $small-font-size;
        }

      }

    }


  }

  .checkout-summary-step-mobile {
    padding: 0 map-get($spacers, 3);
  }

  @include media-breakpoint-down(lg) {}

  @include media-breakpoint-up(lg) {}

}