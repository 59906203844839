@import '../css/shared';

.home-page {

  .product-tabs-section {

    @include media-breakpoint-down(xl) {
      
      overflow: hidden;
      
      .swiper {
        overflow: visible;
      }

    }

    .home-products-tabs {
      .products-tab {
        cursor: pointer;
  
        .icon-container {
          border: 3px solid transparent;
        }
      }
  
      :is(.active, :hover) .products-tab .icon-container {
        border: 3px solid $primary;
      }
    }
  
  }

  .promo-section {
    
    @include media-breakpoint-down(xl) {
      
      overflow: hidden;
      
      .swiper {
        overflow: visible;
      }

    }
    
  }

  .home-brands-tab {
    gap: 1rem;

    button {

      .btn {
        background: $white !important;
        font-size: $small-font-size;

        &:hover {
          border-color: $primary;
          color: $primary;
        }
        
      }

      &.active {

        .btn {
          border-color: $primary;
          color: $primary;
        }

      }

    }

  }

  .newsletter-box .newsletter-box-content mark {
    background: none;
    color: $promo;
    padding: 0;
  }
}