@import 'css/shared';

.account-offcanvas {

  .offcanvas-title,
  .offcanvas-body-title {
    font-size: $h3-font-size;
    font-weight: 700;
    color: $primary;
  }

  .offcanvas-additional{
    border-bottom: 2px solid;
    border-color: $gray-100;
    &:last-child {
        padding-bottom: $spacer(1);
        border-bottom: 0;
      }
  }
  .offcanvas-header svg {
    font-size: 20px;
  }

  @include media-breakpoint-up(md) {}
  @include media-breakpoint-down(md) {

    .offcanvas-body {
      & > div {
        padding: map-get($spacers, 3) !important;
      }
    }

  }

}